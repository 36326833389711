import { currencyList } from '@/const/currencyList';
interface Currency {
   code: string;
   name: string;
   flag: string;
   symbol: any;
}
export const useCurrencyStore = defineStore('currency', () => {
   const currentCurrency = ref<Currency>({
      code: 'USD',
      name: 'International',
      flag: '🇺🇸',
      symbol: '$',
   });
   const currencies = currencyList;
   const detectCurrencyLoading: boolean = ref(false);
   const detectUserCurrency = async () => {
      detectCurrencyLoading.value = true;
      try {
         const response = await fetch('https://ipapi.co/json/');
         const data = await response.json();
         const userCurrency =
            currencyList.find((currency) => currency.code === data.currency) ||
            currencyList.find((currency) => currency.code === 'USD');
         currentCurrency.value = userCurrency;
         return userCurrency;
      } catch (error) {
         currentCurrency.value = currencyList.find(
            (currency) => currency.code === 'USD'
         );
      }
      detectCurrencyLoading.value = false;
   };

   const updateCurrency = async (selectedCurrency: any) => {
      if (selectedCurrency) {
         currentCurrency.value = selectedCurrency;
      } else {
         const userCurrency = await detectUserCurrency();
         if (userCurrency) {
            currentCurrency.value = userCurrency;
         }
      }
   };

   return {
      currentCurrency,
      detectCurrencyLoading,
      detectUserCurrency,
      updateCurrency,
      currencies,
   };
});
